import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import { Outlet } from "react-router-dom";
import { Suspense } from "react";
import styles from "./App.module.scss";
import AuthProvider from "./components/Authprovider/Authprovider";
import Loading from "../src/components/utils/Loading";

function App() {

  return (

    <div className={`${styles.app}`}>
      <AuthProvider>
        <Header />
        <main className={`${styles.containerApp}`}>
          <Suspense fallback={<Loading />}>
            <Outlet />
          </Suspense>
        </main>

        <Footer />

      </AuthProvider>
    </div>

  );
}

export default App;
