const API_USERS = "https://des-gommes-api.vercel.app/api/users";

export async function createUser(newUser) {
    const response = await fetch(`${API_USERS}/register`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(newUser)
    });
    const backResponse = await response.json();
    if (response.ok) {
        return backResponse;
    } else {
        if (backResponse) {
            throw backResponse;
        } else {
            throw new Error("Error API create user");
        }
    }
};

export async function signin(values) {
    const response = await fetch(`${API_USERS}/login`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(values),
        credentials: "include"
    });
    const backResponse = await response.json();
    if (response.ok) {
        return backResponse;
    } else {
        if (backResponse) {
            throw backResponse;
        } else {
            throw new Error("Error API login");
        }
    }
};

export async function signout() {
    await fetch(`${API_USERS}/logout`, {
        method: "DELETE",
        credentials: "include",
    });
}

export async function getConnectedUser() {
    const response = await fetch(`${API_USERS}/userConnected`, {
        credentials: "include"
    });
    return response.json();
}